import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const GetErgone = () => {
  return (
    <div className="container-fluid main-container">
      <div className="container-sm">
        <div className="row">
          <div className="col text-left">
            <h1 className="text-white w3-animate-right font-size-title">
              <b>
                ∑<span className="font-ergo">rgOne</span>
              </b>
            </h1>
            <br />
            <br />
            <h3 className="text-white w3-animate-right font-size-subtitle">
              Decentralised Marketing Powered by Community
            </h3>
            <br />
            <h3 className="text-white font-size-subtitled">
              Currency, NFTs in the Sigmaverse
              <br />
              All in One
              <br />
            </h3>
            <br />
            <br />
          </div>
          <div className="col-sm text-center text-align-center w3-animate-fading img-lueur">
            <img
              src="./img/background.png"
              className="img-fluid"
              alt="ErgOne"
            />
          </div>
          <div className="container-md text-center text-align-center">
            <p className="margin-top-10">
              <a
                href="https://app.spectrum.fi/ergo/swap?quote=fcfca7654fb0da57ecf9a3f489bcbeb1d43b56dce7e73b352f7bc6f2561d2a1b&base=0000000000000000000000000000000000000000000000000000000000000000"
                className="btn btn-outline-secondary btn-get-ergone w3-animate-left font-style-cap"
              >
                GET ERGONE
              </a>
              <a
                href="https://app.ergone.io"
                className="btn btn-outline-secondary w3-animate-left font-style-cap btn-whitepaper margin-left-10"
              >
                LAUNCH APP
              </a>
            </p>
            <br />

            <h3 className="title-alloc">
              Don't be influenced by the industry, discover Communautary
              Marketing
            </h3>
          </div>
        </div>
      </div>
      <div id="pocop" className="text-center pocopweb">
        <div className="container-sm">
          <div className="row">
            <img
              src="./img/pocopweb2.png"
              alt="PocopWeb"
              className="pocopwebimage"
            />

            <br />
            <br />
            <p>
              Discover the innovative{" "}
              <b>𝐏𝐫𝐨𝐨𝐟-𝐨𝐟-𝐂𝐨𝐦𝐦𝐢𝐭𝐦𝐞𝐧𝐭 𝐏𝐫𝐨𝐭𝐨𝐜𝐨𝐥 (𝐏𝐨𝐂𝐨𝐏)</b>{" "}
              <a href="https://app.ergone.io" className="pocoplink">
                app.ergone.io
              </a>
              , a unique approach to blockchain engagement.
            </p>
            <br />
            <br />
            <br />
            <br />

            <p>
              PoCoP rewards active participation in promoting and supporting the
              Ergo ecosystem. By creating and sharing content, engaging with the
              Community, and contributing creatively, you earn $𝐄𝐫𝐠𝐎𝐧𝐞 tokens.
              Your involvement is measured by a <b>𝐕𝐢𝐫𝐚𝐥𝐢𝐭𝐲 𝐒𝐜𝐨𝐫𝐞</b>, ensuring
              fair distribution of rewards.
            </p>
            <br />
            <br />
            <br />
            <br />

            <p>
              Get involved by registering a valid <b>𝐄𝐫𝐠𝐨 𝐀𝐝𝐝𝐫𝐞𝐬𝐬 </b>
              in the "Dashboard" section, and start submitting and voting on
              content.
            </p>

            <p>
              Join us in shaping the future of blockchain engagement, where your
              Commitment and Creativity are the driving forces!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetErgone;
