import React from "react";

const index = () => {
  return (
    <div id="nfts" className="container-fluid main-container padding-bt">
      <div className="row">
        <div className="col text-center">
          <h1 className="sub-title-page-nft">
            NFT<span className="little-nft">s</span>
            <br />
          </h1>
          <h3 className="subtitle-nft">As a tool for Governance</h3>
          <h3 className="title-alloc nft-desc">
            <b className="nft-font-size">
              CyberCollection is a work of fiction representing an alternate
              reality of our future, inspired by the Ergo blockchain and various
              technologies.
            </b>
          </h3>
          <div className="col-sm-4 col-nft-desc">
            Limited to 20 Artworks only
            <br />
            #CyberCollection wants to be as valuable as its members. <br />
            By acquiring these NFTs, you become a unique owner, but also an
            Architect.
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/001.jpg"
                  className="img-thumbnail img-fluid"
                  alt="NFT #1"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/007.jpg"
                  className="img-thumbnail img-fluid"
                  alt="NFT #7"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/002.jpg"
                  className="img-thumbnail img-fluid"
                  alt="NFT #2"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/003.jpg"
                  className="img-thumbnail img-fluid"
                  alt="NFT #3"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/005.jpg"
                  className="img-thumbnail img-fluid"
                  alt="NFT #5"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/006.jpg"
                  className="img-thumbnail img-fluid"
                  alt="NFT #6"
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/008.jpg"
                  className="img-thumbnail img-fluid"
                  alt="NFT #8"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/009.jpg"
                  className="img-thumbnail img-fluid"
                  alt="NFT #9"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/010.jpg"
                  className="img-thumbnail img-fluid"
                  alt="NFT #10"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/004.jpg"
                  className="img-thumbnail img-fluid"
                  alt="NFT #4"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/011.jpg"
                  className="img-thumbnail img-fluid"
                  alt="NFT #11"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/012.png"
                  className="img-thumbnail img-fluid"
                  alt="NFT #12"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/013.png"
                  className="img-thumbnail img-fluid"
                  alt="NFT #12"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/014.png"
                  className="img-thumbnail img-fluid"
                  alt="NFT #12"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/015.png"
                  className="img-thumbnail img-fluid"
                  alt="NFT #12"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/016.png"
                  className="img-thumbnail img-fluid"
                  alt="NFT #12"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/017.png"
                  className="img-thumbnail img-fluid"
                  alt="NFT #12"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/018.png"
                  className="img-thumbnail img-fluid"
                  alt="NFT #12"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/019.png"
                  className="img-thumbnail img-fluid"
                  alt="NFT #12"
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="thumbnail">
                <img
                  src="/img/nfts/020.png"
                  className="img-thumbnail img-fluid"
                  alt="NFT #12"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-center nft-link">
          <a href="https://ergoauctions.org/search?q=Ergone">
            Click here to see available NFTs
          </a>
        </div>
      </div>
    </div>
  );
};

export default index;
