import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";

const Header = () => {
  return (
    <nav className="navbar-expand-lg navbar bg-dark navbar-header text-white navbar-degrad">
      <span className="navbar-text navbar-brand navbar-sigma">
        <a href="./#">∑</a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon navbar-text navbar-sigma">
            <img src="/img/menu-4-24.png"></img>
          </span>
        </button>
      </span>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul class="navbar-nav ms-auto navbar-right">
          <li class="nav-item">
            <Link to="/manifesto" class="nav-link">
              Manifesto
            </Link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="./#tokenomics">
              Tokenomics
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="./#roadmap">
              Road Map
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="./#pocop">
              PoCoP
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="https://github.com/koukarin/Proof-of-Commitment-Protocol/blob/main/README.md"
            >
              GitHub
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="./#nfts">
              NFTs
            </a>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="./RankMyCommitment">
              RankMyCommitment
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
