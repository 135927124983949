import React from "react";
import "./index.scss";
import GetErgone from "./GetErgone";
import Allocation from "./Allocation";
import FirstTextField from "./FirstTextField";
import Tokenomics from "./Tokenomics";
import Roadmap from "./Roadmap";
import SecondTextField from "./SecondTextField";
import FinalTextField from "./FinalTextField";
import Community from "./Community";

const index = () => {
  return (
    <div>
      <GetErgone />
      <Allocation />
      <Tokenomics />
      <Roadmap />
      <FirstTextField />
      <SecondTextField />
      <FinalTextField />
      {/*
      
      <FinalTextField />
  <Community />*/}
    </div>
  );
};

export default index;
