import React from "react";
import "./index.scss";
import { NavLink } from "react-router-dom";

const index = () => {
  return (
    <div className="container-fluid main-container padding-bt">
      <div className="container">
        <table className="table container-rmc">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col" className="left-col">
                Status
              </th>
              <th scope="col" className="right-col">
                Starts at
              </th>
              <th scope="col" className="right-col">
                Voting Power
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">1</th>
              <td className="left-col">Ghost</td>
              <td className="right-col">0 ΣrgOne</td>
              <td className="right-col">0</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td className="left-col">Loser</td>
              <td className="right-col">1 ΣrgOne</td>
              <td className="right-col">1</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td className="left-col">NoName</td>
              <td className="right-col">10 ΣrgOne</td>
              <td className="right-col">3</td>
            </tr>

            <tr>
              <th scope="row">4</th>
              <td className="left-col">Random</td>
              <td className="right-col">25 ΣrgOne</td>
              <td className="right-col">4</td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td className="left-col">Follower</td>
              <td className="right-col">50 ΣrgOne</td>
              <td className="right-col">5</td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td className="left-col">Enthusiast</td>
              <td className="right-col">100 ΣrgOne</td>
              <td className="right-col">10</td>
            </tr>
            <tr>
              <th scope="row">7</th>
              <td className="left-col">Supporter</td>
              <td className="right-col">200 ΣrgOne</td>
              <td className="right-col">15</td>
            </tr>
            <tr>
              <th scope="row">8</th>
              <td className="left-col">Loyal </td>
              <td className="right-col">500 ΣrgOne</td>
              <td className="right-col">20</td>
            </tr>
            <tr>
              <th scope="row">9</th>
              <td className="left-col">Ergonaut </td>
              <td className="right-col">1000 ΣrgOne</td>
              <td className="right-col">25</td>
            </tr>
            <tr>
              <th scope="row">10</th>
              <td className="left-col">Adulator</td>
              <td className="right-col">2500 ΣrgOne</td>
              <td className="right-col">30</td>
            </tr>
            <tr>
              <th scope="row">11</th>
              <td className="left-col">Groupie</td>
              <td className="right-col">5000 ΣrgOne</td>
              <td className="right-col">35</td>
            </tr>
            <tr>
              <th scope="row">12</th>
              <td className="left-col">Lover</td>
              <td className="right-col">10 000 ΣrgOne</td>
              <td className="right-col">40</td>
            </tr>
            <tr>
              <th scope="row">13</th>
              <td className="left-col">Anarchist</td>
              <td className="right-col">15 000 ΣrgOne</td>
              <td className="right-col">45</td>
            </tr>
            <tr>
              <th scope="row">14</th>
              <td className="left-col">Fanatic</td>
              <td className="right-col">20 000 ΣrgOne</td>
              <td className="right-col">50</td>
            </tr>

            <tr>
              <th scope="row">15</th>
              <td className="left-col">Soldier </td>
              <td className="right-col">25 000 ΣrgOne</td>
              <td className="right-col">100</td>
            </tr>
            <tr>
              <th scope="row">16</th>
              <td className="left-col">Warrior</td>
              <td className="right-col">30 000 ΣrgOne</td>
              <td className="right-col">300</td>
            </tr>
            <tr>
              <th scope="row">17</th>
              <td className="left-col">33rd degree</td>
              <td className="right-col">33 333 ΣrgOne</td>
              <td className="right-col">333</td>
            </tr>
            <tr>
              <th scope="row">18</th>
              <td className="left-col">Maximalist</td>
              <td className="right-col">40 000 ΣrgOne</td>
              <td className="right-col">400</td>
            </tr>
            <tr>
              <th scope="row">19</th>
              <td className="left-col">GuRu</td>
              <td className="right-col">50 000 ΣrgOne</td>
              <td className="right-col">500</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default index;
