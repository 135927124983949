import React from "react";

const index = () => {
  return (
    <div className="container-fluid main-container padding-bt align-center">
      <div className="row">
        <div className="col d-flex align-items-center justify-content-center logo-partner">
          <a
            className="ico-link"
            href="https://coinpaprika.com/coin/ergone-ergone/"
          >
            <img
              src="/img/ico/cp_logo_ver.svg"
              alt="CoinPaprika"
              width="300"
              fill="currentColor"
              viewBox="0 0 60 60"
            />
          </a>
        </div>
        <div className="col d-flex align-items-center justify-content-center logo-partner">
          <a className="ico-link" href="https://nonkyc.io/market/ERGONE_USDT">
            <img
              src="/img/ico/NonKyc.png"
              alt="NonKyc"
              width="300"
              fill="currentColor"
              viewBox="0 0 60 60"
            />
          </a>
        </div>
        <div className="col d-flex align-items-center justify-content-center logo-partner">
          <a
            className="ico-link"
            href="https://www.coingecko.com/en/coins/ergone"
          >
            <img
              src="/img/ico/CoinGecko.png"
              alt="Coin Gecko"
              width="300"
              fill="currentColor"
              viewBox="0 0 60 60"
            />
          </a>
        </div>
      </div>
      <div className="row row-partners">
        <div className="col d-flex align-items-center justify-content-center logo-partner">
          <a
            className="ico-link"
            href="https://www.livecoinwatch.com/price/ErgOne-ERGONE"
          >
            <img
              src="/img/ico/LiveCoinWatch.png"
              alt="LiveCoinWatch"
              width="300"
              fill="currentColor"
              viewBox="0 0 60 60"
            />
          </a>
        </div>
        <div className="col d-flex align-items-center justify-content-center logo-partner">
          <a className="ico-link" href="https://coincodex.com/crypto/ergone/">
            <img
              src="/img/ico/coincodex-dark.svg"
              alt="CoinCodex"
              width="300"
              fill="currentColor"
              viewBox="0 0 60 60"
            />
          </a>
        </div>
        <div className="col d-flex align-items-center justify-content-center logo-partner">
          <a
            className="ico-link"
            href="https://app.spectrum.fi/ergo/swap?quote=fcfca7654fb0da57ecf9a3f489bcbeb1d43b56dce7e73b352f7bc6f2561d2a1b&base=0000000000000000000000000000000000000000000000000000000000000000"
          >
            <img
              src="/img/ico/spectrum.svg"
              alt="CoinCodex"
              width="300"
              fill="currentColor"
              viewBox="0 0 60 60"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default index;
