import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import RankMyCommitmentContent from "../components/RankMyCommitment";

const RankMyCommitment = () => {
  return (
    <div>
      <Header />
      <RankMyCommitmentContent />
      <Footer />
    </div>
  );
};

export default RankMyCommitment;
